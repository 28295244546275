import {
	mapState,
	mapActions
} from 'vuex'
import Vue from 'vue'
import {
	Empty,
	Toast,
	Dialog,
	Loading,
	Popup,
} from 'vant'
import {
	setTimeout
} from 'core-js'

Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
	components: {},
	data() {
		return {
			id: '',
			useList: [],

		}
	},
	created() {
		this.getList()
	},

	methods: {
		...mapActions('equityCenter', ['getFlowRecordList']),
		// getFlowRecordList
		back() {
			this.$router.go(-1)
		},
		getList() {

			this.id = this.$route.query.id
			var data = {
				id: this.id

			}
			this.getFlowRecordList(data).then((res) => {
				if (res.code == 200) {
					this.useList = res.data
				} else {

				}
			})
		},
		toMessage(item) {
			this.$router.push({
				name: 'message',
				query: {
					name: item.servicename,
					fId: item.flowRecordId
				}
			})

		}
	},
}
